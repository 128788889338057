import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  activeLanguage = 'en';
  isBrowser = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    translate.addLangs(['en', 'fr', 'nl', 'it', 'ro', 'de', 'pl']);
    translate.setDefaultLang('en');

    this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart),
      first(),
      map((e): string | undefined => e.url.split('/')[1])
    ).subscribe((urlLang) => {
      const langBrowser = this.translate.getBrowserCultureLang()?.slice(0, 2).toLowerCase();
      const langStorage = this.isBrowser ? localStorage.getItem('language') : null;
      if (urlLang && this.translate.langs.includes(urlLang)) {
        translate.use(urlLang);
        this.activeLanguage = urlLang;
      } else if (langStorage && this.translate.langs.includes(langStorage)) {
        translate.use(langStorage);
        this.activeLanguage = langStorage;
      } else if (langBrowser && this.translate.langs.includes(langBrowser)) {
        translate.use(langBrowser);
        this.activeLanguage = langBrowser;
      } else {
        translate.use('en');
      }
    });
  }

  setLanguage(lang: string) {
    if (this.isBrowser) {
      localStorage.setItem("language", lang);
    }
    this.translate.use(lang);
    this.activeLanguage = lang;
  }
}
